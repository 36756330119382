import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { AppPermissions } from 'types/permissions/permissions'
import { isEqualEmails } from 'utils/common'

export const useProjectRole = () => {
  const {
    osContext: { userDetails },
  } = useOs()
  const context = useProject()
  const { isPermitted } = useIsPermitted()

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const isOwner = useMemo(() => {
    return context?.project.owners?.some(owner => isEqualEmails(owner.email, userDetails.email))
  }, [context?.project.owners, userDetails.email])

  const isOwnerOrGlobalManage = isOwner || isGlobalManage
  return { isOwnerOrGlobalManage }
}
