import { WppCard, WppLabel, WppSkeleton, WppToggle } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { AxiosError } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAuthLinkApi } from 'api/wrike/queries/useAuthLinkApi'
import { useContactsMyApi } from 'api/wrike/queries/useContactsMyApi'
import { useWrikeRedirectUrl } from 'auth/wrike/utils'
import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import styles from 'pages/components/projectModal/components/integrations/components/WrikeConnect.module.scss'
import { WrikeProjectCard } from 'pages/components/projectModal/components/integrations/components/WrikeProjectCard'
import { ProjectWrikeDTO } from 'types/projects/projects'
import { is4xxError } from 'utils/error'

enum ConnectionState {
  NOT_AUTHED = 'NOT_AUTHED',
  NOT_LINKED = 'NOT_LINKED',
  LINK_ACTIVE = 'LINK_ACTIVE',
}

const HELP_LINK = 'https://help.wrike.com/hc/en-us/articles/1500005120641-Project-ID'

type ErrorCodes = keyof typeof errorMessages

const errorMessages = {
  WRIKE_ALREADY_ASSOCIATED: 'project.wrike.server_error.already_connected',
  WRIKE_PROJECT_DELETED: 'project.wrike.server_error.project_deleted',
  WRIKE_FOLDER_DELETED: 'project.wrike.server_error.project_deleted',
  WRIKE_WORKFLOW_NOT_FOUND: 'project.wrike.server_error.statuses_not_mapped',

  THIRD_PARTY_ERROR: 'project.wrike.server_error.third_party_error',
}

interface Props {
  wrikeError: MayBeNull<AxiosError<any>>
  wrike?: MayBeNull<ProjectWrikeDTO>
}

export const WrikeConnect = ({ wrikeError, wrike }: Props) => {
  const { t } = useTranslation()

  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.NOT_AUTHED)

  const isProjectLinkActive = useMemo(() => !!wrike?.isConnected, [wrike?.isConnected])

  const redirectUri = useWrikeRedirectUrl()
  const { data: wrikeLink } = useAuthLinkApi({
    params: { redirectUri },
  })

  const { data: myContact, isLoading: isWrikeAuthLoading } = useContactsMyApi()

  const isWrikeAuthed = !!myContact

  const { setValue, setError, clearErrors, watch } = useFormContext()

  useEffect(() => {
    setConnectionState(
      isWrikeAuthed
        ? isProjectLinkActive
          ? ConnectionState.LINK_ACTIVE
          : ConnectionState.NOT_LINKED
        : ConnectionState.NOT_AUTHED,
    )
  }, [isWrikeAuthed, isProjectLinkActive])

  useEffect(() => {
    if (!wrikeError) {
      clearErrors('wrikeProjectId')
      return
    }

    const { code } = wrikeError?.response?.data?.detail || {}

    const errorCodeMessage = errorMessages[code as ErrorCodes]

    const message = errorCodeMessage
      ? t(errorCodeMessage)
      : is4xxError(wrikeError) && t('project.wrike.server_error.4xx', { email: myContact?.primaryEmail })

    if (message) {
      setError('wrikeProjectId', { message })
    }
  }, [clearErrors, myContact?.primaryEmail, setError, t, wrikeError])

  const handleAuth = () => {
    window.open(wrikeLink!.url, '_blank')

    setValue('wrikeConnectionToggle', true)
    setValue('disconnectWrike', false)
  }

  const handleConnect = () => {
    // wrike is already connected, just open an projectId input

    setValue('wrikeConnectionToggle', true)
    setValue('disconnectWrike', false)
  }

  const handleCancel = () => {
    setValue('wrikeConnectionToggle', false)
    setValue('disconnectWrike', true)
  }

  const toggleDisconnect = (checked: boolean) => {
    setValue('wrikeConnectionToggle', checked)
    setValue('disconnectWrike', !checked)
  }

  if (isWrikeAuthLoading) {
    return <WppSkeleton variant="rectangle" height="32px" width="100%" />
  }
  const connectToggle = watch('wrikeConnectionToggle')

  const handleLinkedState = () => (connectToggle ? handleCancel() : handleConnect())

  return (
    <>
      <Flex justify="between" className="wpp-spacing-4-bottom">
        <Flex align="center" data-testid="wrike-connect-row">
          <SvgWrikeLogo className="wpp-spacing-8-right" data-testid="wrike-logo" /> {t('project.wrike.wrike')}
        </Flex>

        {connectionState === ConnectionState.LINK_ACTIVE && (
          <WppToggle
            onWppChange={({ detail: { checked } }) => toggleDisconnect(checked)}
            data-testid="wrike-toggle"
            checked={connectToggle}
            disabled={!isWrikeAuthed}
          />
        )}

        {connectionState === ConnectionState.NOT_AUTHED && (
          <WppToggle
            data-testid="wrike-toggle"
            disabled={!wrikeLink}
            checked={connectToggle}
            onWppChange={() => handleAuth()}
          />
        )}

        {connectionState === ConnectionState.NOT_LINKED && (
          <WppToggle data-testid="wrike-toggle" checked={connectToggle} onWppChange={() => handleLinkedState()} />
        )}
      </Flex>

      {connectionState === ConnectionState.LINK_ACTIVE && (
        <WrikeProjectCard wrike={wrike} showMessage={!connectToggle} />
      )}

      {connectionState === ConnectionState.NOT_LINKED && connectToggle && (
        <WppCard variant="secondary" size="s">
          <Flex justify="between" className="wpp-spacing-8-bottom">
            <WppLabel config={{ text: t('project.wrike.project_info.wrike_project_id') }} typography="s-body" />

            <Link to={HELP_LINK} className={styles.helpLink} target="_blank" rel="noopener noreferrer">
              {t('project.wrike.project_info.help_link')}
            </Link>
          </Flex>

          <FormInput
            name="wrikeProjectId"
            maxMessageLength={Number.POSITIVE_INFINITY}
            data-testid="wrike-project-id-input"
          />
        </WppCard>
      )}
    </>
  )
}
